import { Component, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxDeeplinkerService } from 'ngx-deeplinker';
import { GlobalAlertService } from '../shared/services/global-alert.service';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment-timezone';
import { Locale } from '../shared/model/locale.model';
import { PortfolioService } from '../shared/services/portfolios.service';
import { ValidatorFunctions } from '../shared/validators/validator.functions';
import { PortfolioFormComponent } from '../portfolio-form/portfolio-form.component';
import { TelemetryPointService } from '../shared/services/telemetry-point.service';

@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss', '../shared/shared.styles.scss'],
})
export class CreateComponent {
  SUCCESS = 'Created Successfully';
  BAD_REQUEST = 'Oops, There was a problem with your request';
  NOT_CREATED = 'Oops, There was a problem creating your portfolio';
  REQUIRED = 'required';
  POINTS_CREATED = 'Successfully created';
  POINTS_FAILED = 'Failed to create';
  TELEMETRY_POINTS = 'Telemetry Points';
  FAILED_REGISTRATIONS = 'Not all Registrations could be assigned';
  ALL_FAILED_REGISTRATIONS = 'None of the registrations qualified to be assigned to the portfolio.';

  isSubmitting = false;

  portfolioForm: UntypedFormGroup = new UntypedFormGroup({
    descriptions: new UntypedFormControl({}),
    displayLabels: new UntypedFormControl({}, Validators.required),
    supportedLocales: new UntypedFormControl([new Locale()], Validators.required),
    defaultLocale: new UntypedFormControl('en_US', Validators.required),
    timezone: new UntypedFormControl('America/New_York', Validators.required),
    programId: new UntypedFormControl(''),
    externalReferenceId: new UntypedFormControl('', Validators.required),
    registrations: new UntypedFormControl([], Validators.minLength(0)),
    effectiveFrom: new UntypedFormControl(new Date(), Validators.required),
    effectiveTo: new UntypedFormControl(null, ValidatorFunctions.dateAfterBuilder('effectiveFrom')),
    groupId: new UntypedFormControl(''),
    marketConf: new UntypedFormGroup({
      reserveRate: new UntypedFormControl(''),
      rampRate: new UntypedFormControl(''),
      resourceType: new UntypedFormControl(''),
      areaName: new UntypedFormControl('', Validators.maxLength(300)),
      offerPrice: new UntypedFormControl(''),
      minStableOutput: new UntypedFormControl(''),
    }),
  });
  readonly APPPREFIX = 'prt';
  readonly mode = 'create';
  private portfolioComponentView: PortfolioFormComponent;

  @ViewChild(PortfolioFormComponent, { static: false })
  set portfolioComponent(portfolioDetailsComponent: PortfolioFormComponent) {
    this.portfolioComponentView = portfolioDetailsComponent;
  }

  get portfolioComponent() {
    return this.portfolioComponentView;
  }

  constructor(
    private ngxDeeplinkerService: NgxDeeplinkerService,
    private router: Router,
    private messageService: GlobalAlertService,
    private portfolioService: PortfolioService,
    private translateService: TranslateService,
    private telemetryPointService: TelemetryPointService,
  ) {
    this.portfolioService.selectedPortfolioId$.next('');
    this.translateService.get('portfolio.notification.created_successfully').subscribe((result: string) => {
      this.SUCCESS = result;
      this.BAD_REQUEST = this.translateService.instant('portfolio.notification.bad_request');
      this.NOT_CREATED = this.translateService.instant('portfolio.notification.not_created');
      this.REQUIRED = this.translateService.instant('portfolio.validation.required');
      this.POINTS_CREATED = this.translateService.instant('telemetry_point.create.success');
      this.POINTS_FAILED = this.translateService.instant('telemetry_point.create.failed');
      this.TELEMETRY_POINTS = this.translateService.instant('telemetry_point.create.points');
      this.FAILED_REGISTRATIONS = this.translateService.instant('portfolio.failed_reg');
      this.ALL_FAILED_REGISTRATIONS = this.translateService.instant('portfolio.failed_reg_all');
    });
  }

  handleCancel() {
    this.ngxDeeplinkerService.returnHandler({ appPrefix: this.APPPREFIX });
    this.router.navigate(['/']);
  }

  private async handleEdit(id: string, missingRegs?: string[]) {
    this.isSubmitting = false;
    this.portfolioService.refetchPortfolios();
    let queryParams: any = {};
    if (missingRegs) {
      queryParams.missingRegs = JSON.stringify(missingRegs);
    }
    this.router.navigate([`details/${id}/edit`], { queryParams });
  }

  async handleSubmit() {
    if (!this.portfolioForm.valid) {
      this.messageService.setError(this.REQUIRED);
    } else {
      try {
        this.isSubmitting = true;
        const { points } = this.portfolioComponent;
        const response = await this.portfolioService.createPortfolio({ ...this.portfolioForm.getRawValue() });
        if (points) {
          await this.telemetryPointService.createOrUpdatePoints(response.id, points);
        }
        this.ngxDeeplinkerService.returnHandler({ appPrefix: this.APPPREFIX, callbackValue: response.id });
        if (this.telemetryPointService.hasBrokenPoints) {
          this.handleEdit(response.id);
        } else {
          this.messageService.setSuccess(this.SUCCESS);
          setTimeout(() => {
            this.portfolioService.refetchPortfolios();
            this.router.navigate([`details/${response.id}/view`], {});
          }, 2000);
        }
      } catch (e) {
        if (e) {
          console.log(e);
          if (e.error && e.error.code === 400 && e.error.detail) {
            this.messageService.setError(this.ALL_FAILED_REGISTRATIONS);
          } else if (e.code === 206) {
            this.messageService.setError(this.FAILED_REGISTRATIONS);
            this.handleEdit(e.detail.data.id, e.detail.missingRegs);
          } else {
            const errorMessage = e.error.message;
            if (errorMessage === 'ERR_BAD_REQUEST' || errorMessage === 'Bad Request') {
              this.messageService.setError(this.BAD_REQUEST);
            } else {
              this.messageService.setError(this.NOT_CREATED);
            }
          }
        }
      }
      this.isSubmitting = false;
    }
  }
}
