<form class="context-search-row">
  <div class="tree-search">
    <img
      src="../../../assets/svg/search-icon.svg"
      alt=""
    />
    <input
      autofocus
      data-automation-name="search-text-field"
      matInput (keyup)="applyFilter($event)"
      type="text"
      name="searchText"
      placeholder="{{ 'portfolio.search.portfolio' | translate }}"
    />
  </div>
</form>

<table mat-table [dataSource]="dataSource" [ngClass]="{ stickyTableHeader: !noPortfolios || !loading }">
    <!-- Name Column -->
    <ng-container matColumnDef="display_label">
        <th mat-header-cell *matHeaderCellDef>{{ 'portfolio.list.table.heading.name' | translate }}</th>
        <td mat-cell *matCellDef="let element">
            <div class="portfolio-label ellipsis" title="{{ element.displayLabel || element.id }}">
                {{ element.displayLabel || element.id }}
            </div>
        </td>
    </ng-container>

    <!--  Status Column -->
    <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
            <ng-container [ngSwitch]="element.status">
                <div *ngSwitchCase="'ACTIVE'" class="status-icon status-icon-active" title="Active"></div>
                <div *ngSwitchCase="'PENDING'" class="status-icon status-icon-pending" title="Pending"></div>
                <div *ngSwitchCase="'RETIRED'" class="status-icon status-icon-retired" title="Retired"></div>
            </ng-container>
        </td>
    </ng-container>

    <tr
        mat-row
        *matRowDef="let row; let isEven = even; columns: displayedColumns"
        class="portfolio-list-row"
        [ngClass]="{ 'selected-portfolio': row.id === selectedPortfolioId }"
        (click)="onRowClicked(row.id)"
    ></tr>
</table>

<ng-container *ngIf="!loading">
    <div *ngIf="noPortfolios" class="list-empty">
        {{ 'portfolio.list.none_configured' | translate }}
    </div>
</ng-container>
<div class="spinner-container" *ngIf="loading">
    <mat-spinner mode="indeterminate" diameter="60"></mat-spinner>
</div>
<div class="list-button-container">
    <button (click)="routeToCreatePage()" id="create-button" class="icon-btn x-btn-primary" color="accent">
        <mat-icon>add</mat-icon>
        <span class="btn-text">{{ 'portfolio.list.button.create_portfolio' | translate }}</span>
    </button>
</div>
