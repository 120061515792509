<div class="app-portfolio-view content">
    <header class="sticky-header">
        <div class="left-column">
            <div class="icon-header">
                <div class="icon-container">
                    <mat-icon *ngIf="!loadingPortfolio">bookmarks</mat-icon>
                    <mat-spinner *ngIf="loadingPortfolio" matSuffix diameter="30" color="warn"></mat-spinner>
                </div>
                <h1 id="view-title" class="ellipsis" data-automation="name" title="{{ portfolio.displayLabel }}">
                    <ng-container *ngIf="!loadingPortfolio">{{ portfolio.displayLabel }}</ng-container>
                    <ng-container *ngIf="loadingPortfolio">{{
                        'portfolio.loading_portfolio' | translate
                    }}</ng-container>
                </h1>
                <p class="subtitle" data-automation="subtitle">{{ 'portfolio.view.subtitle' | translate }}</p>
            </div>
        </div>
        <div class="right-column" data-automation="rightColumn">
            <button
                class="icon-btn x-btn-primary"
                color="accent"
                aria-label="delete"
                (click)="openDeleteDialog()"
                data-automation="delete"
                [disabled]="this.portfolioId === ''"
            >
                <mat-icon>delete</mat-icon>
                <span class="btn-text"> {{ 'portfolio.button.delete' | translate }}</span>
            </button>

            <button
                class="icon-btn x-btn-primary"
                color="accent"
                aria-label="Edit"
                (click)="handleEdit()"
                data-automation="portfolioEdit"
            >
                <mat-icon>edit</mat-icon>
                <span class="btn-text"> {{ 'portfolio.button.edit' | translate }}</span>
            </button>
        </div>
    </header>
    <div class="page-content">
        <form #view="ngForm" class="portfolio-form">
            <div class="form-group" data-automation="Forms">
                <div class="view-field-container small">
                    <mat-label data-automation="status" class="field-label"
                        >{{ 'portfolio.status' | translate }}:</mat-label
                    >
                    <div class="field-value">
                        {{ getStatusForPortfolio() }}
                    </div>
                </div>
                <div class="view-field-container small">
                    <mat-label data-automation="timezone" class="field-label"
                        >{{ 'portfolio.timezone' | translate }}:</mat-label
                    >
                    <div class="field-value">
                        <span matTooltip="{{ getTimezoneDisplayLabel() || '-' }}" matTooltipPosition="above">
                            {{ getTimezoneDisplayLabel() || '-' }}
                        </span>
                    </div>
                </div>
                <div class="view-field-container small">
                    <mat-label data-automation="locale" class="field-label"
                        >{{ 'portfolio.locale' | translate }}:</mat-label
                    >
                    <div class="field-value">
                        {{ getLocaleDisplayLabel() }}
                    </div>
                </div>

                <div class="view-field-container small">
                    <mat-label data-automation="program" class="field-label"
                        >{{ 'portfolio.program' | translate }}:</mat-label
                    >
                    <div class="field-value">
                        <span matTooltip="{{ program ? program.displayLabel : '-' }}" matTooltipPosition="above">
                            {{ program ? program.displayLabel : '-' }}
                        </span>
                    </div>
                </div>

                <div class="view-field-container small">
                    <mat-label data-automation="portfolioId" class="field-label"
                        >{{ 'portfolio.external_reference_id' | translate }}:</mat-label
                    >
                    <div class="field-value">
                        {{ portfolio.externalReferenceId }}
                    </div>
                </div>

                <div class="view-field-container small">
                    <mat-label data-automation="effectiveFrom" class="field-label"
                        >{{ 'portfolio.effective_from' | translate }}:</mat-label
                    >
                    <div class="field-value">
                        {{ portfolio.effectiveFrom | date : 'shortDate' : '' : locale }}
                    </div>
                </div>

                <div class="view-field-container small">
                    <mat-label data-automation="effectiveTo" class="field-label"
                        >{{ 'portfolio.effective_to' | translate }}:</mat-label
                    >
                    <div class="field-value">
                        {{ portfolio.effectiveTo | date : 'shortDate' : '' : locale }}
                    </div>
                </div>

                <div class="view-field-container small">
                    <mat-label data-automation="groupId" class="field-label label-info"
                        >{{ 'portfolio.group_id' | translate }}:
                        <mat-icon
                            svgIcon="general-info"
                            matSuffix
                            class="tooltip-icon"
                            matTooltip="{{ 'portfolio.tooltip.group_id' | translate }}"
                        ></mat-icon>
                    </mat-label>
                    <div class="field-value" data-automation="groupIdValue">
                        <span matTooltip="{{ portfolio.groupId || '-' }}" matTooltipPosition="above">
                            {{ portfolio.groupId || '-' }}
                        </span>
                    </div>
                </div>
            </div>

            <div *ngIf="portfolio.marketConf">
                <h4 class="form-header">
                    {{ 'portfolio.market_configuration' | translate }}
                </h4>
                <div class="form-group" data-automation="marketFields">
                    <div class="view-field-container small">
                        <mat-label class="field-label" data-automation="reserveRate     ">
                            {{ 'portfolio.market_configuration.reserve_rate' | translate }}:
                        </mat-label>
                        <div class="field-value">
                            {{ portfolio.marketConf.reserveRate || '-' }}
                        </div>
                    </div>
                    <div class="view-field-container small">
                        <mat-label class="field-label" data-automation="resourceType">
                            {{ 'portfolio.market_configuration.resource_type' | translate }}:
                        </mat-label>
                        <div class="field-value">
                            {{ getResourceTypeDisplayLabel() }}
                        </div>
                    </div>
                    <div class="view-field-container small">
                        <mat-label class="field-label" data-automation="rampRate">
                            {{ 'portfolio.market_configuration.ramp_rate' | translate }}:
                        </mat-label>
                        <div class="field-value">
                            {{ portfolio.marketConf.rampRate || '-' }}
                        </div>
                    </div>
                    <div class="view-field-container small">
                        <mat-label class="field-label label-info" data-automation="areaName">
                            {{ 'portfolio.market_configuration.area_name' | translate }}:
                            <mat-icon
                                svgIcon="general-info"
                                matSuffix
                                class="tooltip-icon"
                                matTooltip="{{ 'portfolio.tooltip.area_name' | translate }}"
                            ></mat-icon>
                        </mat-label>
                        <div class="field-value" data-automation="areaNameValue">
                            <span matTooltip="{{ portfolio.marketConf.areaName || '-' }}" matTooltipPosition="above">
                                {{ portfolio.marketConf.areaName || '-' }}
                            </span>
                        </div>
                    </div>
                    <div class="view-field-container small">
                        <mat-label class="field-label label-info" data-automation="offerPrice">
                            {{ 'portfolio.market_configuration.offer_price' | translate }}:
                            <mat-icon
                                svgIcon="general-info"
                                matSuffix
                                class="tooltip-icon"
                                matTooltip="{{ 'portfolio.tooltip.offer_price' | translate }}"
                            ></mat-icon>
                        </mat-label>
                        <div class="field-value" data-automation="offerPriceValue">
                            {{ portfolio.marketConf.offerPrice || '-' }}
                        </div>
                    </div>
                    <div class="view-field-container small">
                        <mat-label class="field-label" data-automation="minStableOutput">
                            {{ 'portfolio.market_configuration.min_stable_output' | translate }}:
                        </mat-label>
                        <div class="field-value" data-automation="minStableOutputValue">
                            {{ portfolio.marketConf.minStableOutput || '-' }}
                        </div>
                    </div>
                </div>
            </div>

            <h4 class="form-header">
                {{ 'portfolio.registrations' | translate }}
            </h4>
            <div class="form-group">
                <h6 class="form-subheader">
                    <div>
                        {{ 'portfolio.registrations_number' | translate }}:
                        {{ portfolio.registrations.length }}
                    </div>
                </h6>
                <mat-list data-automation="registrationsList" id="registration-list" *ngIf="portfolio.registrations">
                    <mat-list-item *ngFor="let registrationId of portfolio.registrations" role="listitem">
                        <ng-container [ngSwitch]="getRegistrationData(registrationId, 'status')">
                            <span *ngSwitchCase="'ACTIVE'" class="status-icon status-icon-active" title="Active"></span>
                            <span
                                *ngSwitchCase="'INACTIVE'"
                                class="status-icon status-icon-inactive"
                                title="Inactive"
                            ></span>
                        </ng-container>
                        <span class="reg-name ellipsis">{{ getRegistrationData(registrationId, 'displayLabel') }}</span>
                    </mat-list-item>
                </mat-list>
            </div>

            <!-- Telemetry Points -->
            <ng-container>
                <div class="telemetry-points-header form-header">
                    <h2 data-automation="points-header">
                        {{ 'telemetry_point.title' | translate }}
                    </h2>
                </div>

                <div class="points-content">
                    <div class="empty-points" *ngIf="!hasPoints">
                        <div><mat-icon svgIcon="noData"></mat-icon></div>
                        <h6>{{ 'telemetry_point.empty_title' | translate }}</h6>
                        <div data-automation="empty-points" class="empty-filler">
                            {{ 'telemetry_point.empty' | translate }}
                        </div>
                    </div>

                    <ng-container *ngIf="hasPoints">
                        <ng-container *ngFor="let point of points; let i = index; let c = count">
                            <app-telemetry-point
                                *ngIf="!point.deleted"
                                [mode]="mode"
                                [point]="point"
                                [appData]="appData"
                                [portfolioName]="portfolio.displayLabel"
                                [reportingIntervals]="reportingIntervals"
                                [dataProviders]="dataProviders"
                                [index]="i"
                                [count]="c"
                                [multiLocaleConfig]="multiLocaleConfig"
                                [portfolio]="portfolioId"
                            >
                            </app-telemetry-point>
                        </ng-container>
                    </ng-container>
                </div>
            </ng-container>
        </form>
    </div>
</div>
