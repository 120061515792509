import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SidenavService } from '../shared/services/sidenav.service';
import { PortfolioService } from '../shared/services/portfolios.service';
import { MatTableDataSource } from '@angular/material/table';
import { Portfolio } from '../shared/model/portfolio';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
})
export class ListComponent implements OnInit {
  displayedColumns: string[] = ['status', 'display_label'];
  loading = true;
  noPortfolios = false;
  dataSource: MatTableDataSource<Portfolio>;
  selectedPortfolioId = '';
  programs: any[];
  isInit = true;

  constructor(
    private router: Router,
    private portfolioService: PortfolioService,
    private sidenavService: SidenavService,
  ) {
    this.portfolioService.selectedProgram$.subscribe(async program => {
      if (program && program.id) {
        this.loading = true;
        await this.portfolioService.getPortfoliosByProgram(program.id);
        this.loading = false;
      }
    });

    this.portfolioService.selectedPortfolioId$.subscribe(async portfolioId => {
      if (portfolioId) {
        this.selectedPortfolioId = portfolioId;
      }
    });

    this.portfolioService.portfolios$.subscribe(portfolios => {
      if (portfolios) {
        this.dataSource = new MatTableDataSource(portfolios);
        this.noPortfolios = portfolios.length === 0;
      } else {
      }
    });
  }

  async ngOnInit() {}

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  onRowClicked(id) {
    this.sidenavService.closeIfMobile();
    this.router.navigate([`details/${id}/view`], {});
  }

  routeToCreatePage() {
    this.sidenavService.closeIfMobile();
    this.router.navigate([`create`], {});
  }
}
