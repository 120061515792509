import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlContainer, UntypedFormGroup, NgForm } from '@angular/forms';
import { NgxDeeplinkerService } from 'ngx-deeplinker';
import { CookieService as NgxCookieService } from 'ngx-shared-services';
import { ResourceTypeService } from '../shared/services/resource-types.service';
import { ResourceType } from '../shared/model/resourceType.model';
import { LocalesService } from '../shared/services/locales.service';
import { TimezonesService } from '../shared/services/timezones.service';
import { Timezone } from '../shared/model/timezone.model';
import { Locale, LocaleBaseConfig } from '../shared/model/locale.model';
import { DeeplinksService } from '../shared/services/deeplinks.service';
import { TranslateService } from '@ngx-translate/core';
import { PortfolioService } from '../shared/services/portfolios.service';
import { Status } from '../shared/model/status.model';
import { StatusesService } from '../shared/services/statuses.service';
import { TelemetryPoint } from '../shared/model/telemetry-point.model';
import { TelemetryPointService } from 'src/app/shared/services/telemetry-point.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { SupportingDataService } from 'src/app/shared/services/supporting-data.service';
import { OrganizationsService } from '../shared/services/organizations.service';
import { Context, ContextSelectorService } from 'ngx-global-nav';

@Component({
  selector: 'app-portfolio-form',
  templateUrl: './portfolio-form.component.html',
  styleUrls: ['./portfolio-form.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class PortfolioFormComponent implements OnInit, AfterViewInit, OnDestroy {
  readonly EDIT = 'edit';
  readonly CREATE = 'create';

  APPPREFIX = 'prt';

  locales: Locale[];
  timezones: Timezone[];
  resourceTypes: ResourceType[];
  portfolioId = '';

  registrationsList: any[] = [];
  statuses: Status[];

  multiLocaleConfig: LocaleBaseConfig = {
    supportedLocales: [new Locale()],
    locales: [new Locale()],
    defaultLocale: new Locale(),
    id: '',
    displayLabelKey: 'displayLabel',
    localeKey: 'localeName',
  };

  namePlaceholder = '';
  nameLabel = '';
  userLocale = 'en_US';
  subscriptions = [];
  descriptionPlaceholder = '';
  descriptionLabel = '';

  // Telemetry Points
  selectedChannels: any[] = [];
  selectedDataProvider: string;
  loadingDataProviders = true;
  sourceIds: any[] = [];
  reportingIntervals: any[];
  dataProviders: any[];

  loadingRegistrations;

  @Input() portfolioForm: UntypedFormGroup;
  @Input() missingRegs: any;
  errors = 0;

  @Input()
  set mode(mode: string) {
    this._mode = mode;
  }

  set LocaleConfig(localeConfig: any) {
    this.multiLocaleConfig = localeConfig;
    this.buildSupportedLocales();
  }

  get mode() {
    return this._mode;
  }

  get isEditMode() {
    return this.mode === this.EDIT;
  }

  get isCreateMode() {
    return this.mode === this.CREATE;
  }

  get displayLabels() {
    return this.portfolioForm.get('displayLabels');
  }

  get descriptions() {
    return this.portfolioForm.get('descriptions');
  }

  get displayLabel() {
    return this.portfolioForm.get('displayLabel');
  }

  get supportedLocales() {
    return this.portfolioForm.get('supportedLocales');
  }

  get timezone() {
    return this.portfolioForm.get('timezone');
  }

  get registrations() {
    return this.portfolioForm.get('registrations');
  }

  get defaultLocale() {
    return this.portfolioForm.get('defaultLocale');
  }

  get effectiveFrom() {
    return this.portfolioForm.get('effectiveFrom');
  }

  get effectiveTo() {
    return this.portfolioForm.get('effectiveTo');
  }

  get externalReferenceId() {
    return this.portfolioForm.get('externalReferenceId');
  }

  get points(): TelemetryPoint[] {
    return this._points;
  }

  get hasPoints() {
    return Boolean(this._points && this._points.find((p) => !p.deleted));
  }

  private _mode: string;
  private _points: TelemetryPoint[] = [];
  org = '';

  constructor(
    private localesService: LocalesService,
    private timezonesService: TimezonesService,
    private portfolioService: PortfolioService,
    private ngxDeeplinkerService: NgxDeeplinkerService,
    private deeplinksInternalService: DeeplinksService,
    private translateService: TranslateService,
    private ngxCookieService: NgxCookieService,
    private statusesService: StatusesService,
    private resourceTypeService: ResourceTypeService,
    private telemetryPointService: TelemetryPointService,
    private domSanitizer: DomSanitizer,
    iconRegistry: MatIconRegistry,
    private matIconRegistry: MatIconRegistry,
    private supportingDataService: SupportingDataService,
    private orgSelectorService: ContextSelectorService,
    private organizationsService: OrganizationsService,
  ) {
    this.timezones = timezonesService.timezones;
    this.org = this.ngxCookieService.getCookie('preferred-org-id') || '';
    this.nameLabel = this.translateService.instant('portfolio.portfolio_name');
    this.namePlaceholder = this.translateService.instant('portfolio.create.placeholder.portfolio_name');
    this.userLocale = this.ngxCookieService.getCookie('locale') || 'en_US';
    this.descriptionLabel = this.translateService.instant('telemetry_point.create.description');
    this.descriptionPlaceholder = this.translateService.instant('telemetry_point.create.placeholder.description');
    this.statuses = statusesService.statuses;
    this.matIconRegistry.addSvgIcon(
      'noData',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/no-data.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'plusButton',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/plus-button.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'general-info',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/svg/general-info.svg'),
    );
    const reportingIntervalsSub = this.supportingDataService.reportingIntervals$.subscribe((reportingIntervals) => {
      reportingIntervals.sort((a: any, b: any) => {
        if (parseInt(a.id, 10) < parseInt(b.id, 10)) {
          return -1;
        } else if (parseInt(a.id, 10) > parseInt(b.id, 10)) {
          return 1;
        } else {
          return 0;
        }
      });

      this.reportingIntervals = reportingIntervals;
    });

    const contextSub = this.orgSelectorService.currentContext$.subscribe(async (orgs: Context[]) => {
      //Hardcode organization id
      // const org = orgs[0];
      let org = {
        id: '974a7baf-9da1-4e1b-ae45-fe148abb77ce',
      };
      this.org = org.id;
      if (org.id) {
        this.loadingDataProviders = true;
        await this.supportingDataService.setDataProviders(org.id);
        this.loadingDataProviders = false;
      }
    });

    const dataProvidersSub = this.supportingDataService.dataProviders$.subscribe(async (dataProviders) => {
      if (dataProviders) {
        this.dataProviders = dataProviders;
      }
    });

    this.subscriptions.push(...[reportingIntervalsSub]);
  }

  ngOnInit() {
    this.loadDynamicLists();

    const resourceTypesSub = this.resourceTypeService.resourceTypes$.subscribe((resourceTypes) => {
      this.resourceTypes = resourceTypes;
    });

    const telemetrySub = this.telemetryPointService.brokenPoints$.subscribe(async (telemetryPoints) => {
      if (this.isEditMode) {
        const brokenPointsIds = telemetryPoints.filter((point) => point.toEdit).map((point) => point.id);
        const existingPoints = telemetryPoints.filter((point) => !brokenPointsIds.includes(point.id));
        const brokenPoints = telemetryPoints.filter((point) => point.toEdit);
        this._points = [...existingPoints, ...brokenPoints];
        this.updateSelectedChannels();
      }
    });

    const localesSub = this.localesService.locales$.subscribe((locales) => {
      this.locales = locales;
      const locale = this.localesService.checkForLocale(this.userLocale, locales) || locales[0];
      this.multiLocaleConfig.defaultLocale = locale;
      this.supportedLocales.patchValue([locale]);

      if (this.isCreateMode) {
        this.multiLocaleConfig.supportedLocales = [locale];
        this.defaultLocale.patchValue(locale.localeName);
      } else if (this.isEditMode) {
        this.buildSupportedLocales();
      }
    });

    this.subscriptions.push(...[telemetrySub, localesSub]);

    if (this.isCreateMode) {
      const programSub = this.portfolioService.selectedProgram$.subscribe((program) => {
        this.portfolioForm.get('programId').setValue(program.id);
      });
      this.subscriptions.push(...[programSub]);
    } else if (this.isEditMode) {
      const portfolioSub = this.portfolioService.portfolio$.subscribe(async (portfolio) => {
        if (portfolio) {
          this.portfolioId = portfolio.id;
          this.populatePortfolio(portfolio);
          this.buildSupportedLocales();

          // Only call points if there is no broken points
          if (this.isEditMode && !this.telemetryPointService.hasBrokenPoints) {
            this._points = await this.telemetryPointService.getPointsForPortfolio(portfolio.id);
            this.updateSelectedChannels();
          }
        }
      });
      this.subscriptions.push(...[portfolioSub]);
    }

    const registrationsSub = this.portfolioService.registrations$.subscribe((registrations) => {
      if (this.missingRegs) {
        this.errors = this.missingRegs.length;
        this.missingRegs.forEach((missingReg: any) => {
          registrations = registrations.map((reg) => (reg.id === missingReg ? { ...reg, hasError: true } : reg));
        });
      }
      registrations = registrations.filter((reg) => !reg.portfolioId || reg.portfolioId === this.portfolioId);
      this.registrationsList = registrations;
    });

    this.statusesService.setStatuses();
    this.subscriptions.push(...[resourceTypesSub, localesSub, registrationsSub]);
  }

  ngAfterViewInit() {}

  limitLocaleSelectList(num: number, locale: Locale) {
    return this.supportedLocales.value.length > num - 1 && !this.supportedLocales.value.includes(locale);
  }

  handleSelectionChange() {
    setTimeout(() => {
      this.triggerValidation();
    }, 100);
    if (this.supportedLocales.value.length === 0) {
      this.supportedLocales.patchValue([...this.supportedLocales.value, this.multiLocaleConfig.defaultLocale]);
      this.multiLocaleConfig.supportedLocales = [
        ...this.multiLocaleConfig.supportedLocales,
        this.multiLocaleConfig.defaultLocale,
      ];
    } else {
      this.multiLocaleConfig.supportedLocales = this.supportedLocales.value;
    }
  }

  buildSupportedLocales() {
    if (this.supportedLocales.value && this.displayLabels?.value && this.locales) {
      this.supportedLocales.patchValue([]);
      this.multiLocaleConfig.supportedLocales = [];
      const keys = Object.keys(this.displayLabels.value);
      for (let key of keys) {
        const localeFound = this.locales.find((locale) => locale.localeName === key);
        if (localeFound) {
          this.supportedLocales.patchValue([...this.supportedLocales.value, localeFound]);
          this.multiLocaleConfig.supportedLocales.push(localeFound);
        }
      }
    }
  }

  triggerValidation() {
    this.displayLabels.updateValueAndValidity();
    this.descriptions.updateValueAndValidity();
  }

  private async loadDynamicLists() {
    this.localesService.setLocales();
    this.timezonesService.setTimezones();
    this.resourceTypeService.setResourceTypes();
  }

  //Telemetry points
  populatePortfolio(portfolio) {
    this.clearTelemetryPoints();
  }

  clearTelemetryPoints() {
    this._points = [];
  }

  clearSourceIds() {
    this.sourceIds = [];
  }

  addDynamicPoint() {
    const point: TelemetryPoint = this.pointPlaceholder();

    if (this._points) {
      this._points.push(point);
    } else {
      this._points = [point];
    }
  }

  deleteDynamicPoint(index: number) {
    const editedPoints = this._points.map((point: any, pointIndex) => {
      if (pointIndex === index) {
        return { ...point, deleted: true };
      }

      return point;
    });

    this.clearTelemetryPoints();

    setTimeout(() => {
      this._points = editedPoints;
      this.updateSelectedChannels();
      this.clearSourceIds();
    }, 250);
  }

  updateSourceIds() {
    this.sourceIds = this._points.map((point) => point.sourceId);
  }

  updateSelectedChannels() {
    this.selectedChannels = this._points.map((point) => (point.deleted != true ? `${point.channelId}` : null));
  }

  pointPlaceholder(): TelemetryPoint {
    return new TelemetryPoint({
      channel_id: '-1',
      data_provider: '-1',
      default_locale: this.defaultLocale.value,
      display_labels: { en_US: '' },
      reporting_interval: '-1',
      portfolio_id: this.portfolioId,
      source_id: '',
      timezone: this.timezone.value,
      vee_bypass: true,
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }
}
